import styled from "styled-components"
import { colors } from "../../../../consts/colors"
import ReactMarkdown from "react-markdown"
import {
  P,
  Container as C,
  H1 as Title,
  H5,
  H3 as SubTitle,
} from "../../../common/common.style"

export const RM = styled(ReactMarkdown)`
  padding-top: 3rem;
  p + h3,
  p + h2,
  p + h4,
  ul + h3,
  ul + h2,
  ul + h4,
  ol + h3,
  ol + h2,
  ol + h4 {
    margin-top: 6rem;
  }
  p + ul,
  p + ol {
    margin-top: -2rem;
  }
  ul {
    margin-bottom: 4rem;
    li {
      font-size: 1.8rem;
      line-height: 1.6;
      padding-left: 1rem;
      margin-left: 2rem;
      margin-bottom: 2rem;
      position: relative;
      &::markdown {
        content: "⬤";
        display: inline-block;
        font-size: 0.35em;
        border-radius: 50%;
        position: absolute;
        left: 2em;
        top: 1.4em;
      }
    }
  }
  ol {
    margin-bottom: 4rem;
    li {
      font-size: 1.8rem;
      line-height: 1.6;
      padding-left: 4rem;
      margin-bottom: 2rem;
      position: relative;
    }
  }
  p {
    font-family: "Poppins", serif;
    color: ${colors.jetblack};
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 1.6;
    ${({ align }) => align && `text-align: ${align}`};
    margin-bottom: 4rem;
    margin-right: 0;
    @media (max-width: 1024px) {
      font-size: 1.8rem;
      ${({ talign }) => talign && `text-align: ${talign}`};
    }
    @media (max-width: 767px) {
      font-size: 1.8rem;
      ${({ malign }) => malign && `text-align: ${malign}`};
    }
    ${({ hero }) =>
      hero &&
      `
      margin: 0 auto;
    `}
    @media (min-width: 325px) {
      max-width: 100%;
    }
    @media (min-width: 767px) {
      max-width: 620px;
    }
    @media (min-width: 1025px) {
      max-width: 1180px;
    }
    em {
      font-family: "Poppins", serif;
      font-weight: 500;
      color: ${({ color }) => (color ? color : colors.jetblack)};
    }
    del {
      display: block;
      font-family: "Poppins", sans-serif;
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: ${colors.grey};
      margin-top: -3rem;
      margin-bottom: 4rem;
      text-decoration: none;
    }
    code {
      font-family: "Poppins", serif;
      display: block;
      font-size: 4.8rem;
      line-height: 1em;
      text-align: center;
      margin-top: -2rem;
      margin-bottom: 4rem;
      letter-spacing: 2rem;
      color: ${colors.jetblack};
    }
  }
  h1 {
    font-family: "Poppins", serif;
    font-size: 6.4rem;
    line-height: 7.6rem;
    font-weight: 600;
    color: ${({ color }) => (color ? color : colors.jetblack)};
    line-height: 1.2;
    margin: 0 auto 2rem;
    .breakLine {
      display: block;
      @media (max-width: 767px) {
        display: inline;
      }
    }
    ${({ align }) => align && `text-align: ${align}`};
    @media (max-width: 1024px) {
      font-size: 6.4rem;
      ${({ talign }) => talign && `text-align: ${talign}`};
    }
    @media (max-width: 767px) {
      font-size: 3.6rem;
      ${({ malign }) => malign && `text-align: ${malign}`};
    }
  }
  h2 {
    display: block;
    width: 100%;
    font-family: "Poppins", serif;
    font-weight: 600;
    color: ${({ color }) => (color ? color : colors.jetblack)};
    font-size: 4.8rem;
    line-height: 1.2;
    margin-bottom: 4rem;
    ${({ align }) => align && `text-align: ${align}`};
    @media (max-width: 1024px) {
      font-size: 4.8rem;
      ${({ talign }) => talign && `text-align: ${talign}`};
    }
    @media (max-width: 767px) {
      font-size: 3.6rem;
      ${({ malign }) => malign && `text-align: ${malign}`};
    }
  }
  h3 {
    font-family: "Poppins", serif;
    font-weight: 500;
    color: ${({ color }) => (color ? color : colors.jetblack)};
    font-size: 3.6rem;
    line-height: 1.2;
    margin-bottom: 4rem;
    ${({ align }) => align && `text-align: ${align}`};
    @media (max-width: 1199px) {
      font-size: 3.6rem;
      ${({ talign }) => talign && `text-align: ${talign}`};
    }
    @media (max-width: 767px) {
      font-size: 3.4rem;
      ${({ malign }) => malign && `text-align: ${malign}`};
    }
  }
  h4,
  h5,
  h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    color: ${({ color }) => (color ? color : colors.jetblack)};
    font-size: 2.4rem;
    line-height: 1.4;
    margin-bottom: 2rem;
    ${({ align }) => align && `text-align: ${align}`};
    @media (max-width: 1024px) {
      font-size: 3.4rem;
      ${({ talign }) => talign && `text-align: ${talign}`};
    }
    @media (max-width: 767px) {
      font-size: 3.4rem;
      ${({ malign }) => malign && `text-align: ${malign}`};
    }
  }
  img {
    display: block;
    max-width: 100%;
    margin-bottom: 1rem;
    border-radius: 7px;
  }
  iframe {
    width: 100%;
    aspect-ratio: 16/9;
    border-radius: 0.75rem;
    margin-bottom: 1rem;
  }
  a {
    color: inherit;
    text-decoration: underline;
    &:hover {
      color: inherit;
    }
  }
  blockquote {
    p {
      font-family: "Poppins", serif;
      font-size: 2.4rem;
      line-height: 1.38em;
      margin-bottom: 4rem;
      color: ${colors.jetblack};
    }
  }
`

export const Container = styled(C)`
  padding-top: 14rem;
  padding-bottom: 12rem;
  @media (max-width: 767px) {
    padding-top: 12rem;
    padding-bottom: 2rem;
  }
`

export const Date = styled(P)`
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: ${colors.grey};
  margin-bottom: 1rem;
`

export const H1 = styled(Title)`
  font-family: "Poppins", serif;

  margin-left: 0;
  margin-right: 0;
  @media (min-width: 325px) {
    margin-left: 0;
    margin-right: 0;
    font-size: 3.6rem;
    line-height: 4.3rem;
    margin-bottom: 2rem;
  }
  @media (min-width: 767px) {
    margin-left: 0;
    margin-right: 0;
    font-size: 4.8rem;
    line-height: 5.7rem;
    margin-bottom: 4rem;
  }
  @media (min-width: 1025px) {
    margin-left: 0;
    margin-right: 0;
    font-size: 6.4rem;
    line-height: 7.6rem;
    margin-bottom: 4rem;
  }
`

export const H3 = styled(SubTitle)`
  font-family: "Poppins", serif;
  color: ${colors.freespeechred};
  text-transform: uppercase;

  margin-left: 0;
  margin-right: 0;
  @media (min-width: 325px) {
    margin-left: 0;
    margin-right: 0;
    font-size: 1rem;

    margin-bottom: 0.25rem;
  }
  @media (min-width: 767px) {
    margin-left: 0;
    margin-right: 0;
    font-size: 1.5rem;

    margin-bottom: 0.25rem;
  }
  @media (min-width: 1025px) {
    margin-left: 0;
    margin-right: 0;
    font-size: 2rem;

    margin-bottom: 0.3rem;
  }
`

export const ReadingTime = styled(P)`
  color: ${colors.grey};
  margin-bottom: 4rem;
`

export const FeaturedImageWrap = styled.div`
  position: relative;
  z-index: 1;
`

export const ImageEffect = styled.img`
  position: absolute;
  z-index: -1;
  top: 1rem;
  width: calc(100% + 2rem);
  object-fit: cover;
  left: -1rem;
  right: -1rem;
  border-radius: 15px;
  opacity: 0.15;
  filter: blur(5px);
  opacity: 0;
`

export const Image = styled.img`
  display: block;
  width: 100%;
  object-fit: ${({ fillParent }) => (fillParent ? `contain` : `cover`)};
  margin-bottom: 1rem;
  border-radius: 1rem;
  position: relative;
  z-index: 2;
  height: ${({ fillParent }) => (fillParent ? `100%` : `auto`)};
`

export const Credits = styled(Date)`
  margin-bottom: 4rem;
`

export const Dots = styled(P)`
  text-align: center;
  margin-bottom: 4rem;
  color: ${colors.jetblack};
`

export const Quote = styled(H5)`
  margin-bottom: 4rem;
`

export const Subtitle = styled(H3)`
  margin-top: 4rem;
  margin-bottom: 4rem;
`

export const Text = styled(P)`
  font-family: "Poppins", serif;
  color: ${colors.jetblack};
`

export const Author = styled.div`
  display: flex;
  align-items: center;
`

export const Avatar = styled.img`
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 2rem;
`

export const AuthorName = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: ${colors.jetblack};
  font-weight: 600;
  margin-bottom: 0;
`

export const AuthorPosition = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: ${colors.silver};
  font-weight: 400;
  margin-bottom: 0;
`

export const PostDetails = styled.div`
  height: auto;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  @media (max-width: 678px) {
    flex-direction: column;
  }
`

export const PostDetailsChild = styled.div`
  width: 100%;
  &:nth-child(1) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: stretch;
    padding: 25px;
  }
`

export const PostDetailsCategories = styled.div`
  min-width: 50%;
`

export const PostCategory = styled.div`
  height: auto;
  display: flex;
  justify-self: end;
  color: ${({ color }) => (color ? color : colors.jetblack)};
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  display: inline-block;

  ::after {
    content: ", ";
  }

  :last-child {
    ::after {
      content: ".";
    }
  }
  @media (max-width: 678px) {
    font-size: 16px;
  }
`

export const PostCategoryEmpty = styled.div`
  height: auto;
  display: flex;
  justify-self: end;
  color: ${({ color }) => (color ? color : colors.jetblack)};
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  display: inline-block;
`

export const CategoriesContainer = styled.div`
  margin-bottom: 30px;
  @media (max-width: 678px) {
    margin-bottom: 5px;
  }
`
