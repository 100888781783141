import React, { useState, useEffect } from 'react';
import Layout from '../src/components/layout';
import Content from '../src/components/sections/exercises/content/content';
import Seo from '../src/components/seo';
import { graphql } from 'gatsby';
import TopBar from '../src/components/common/topBar';

export const query = graphql`
  query($title: String!) {
    allStrapiExercises(filter: { title: { eq: $title } }) {
      edges {
        node {
          content
          title
          Paid
          slug
          description
          sub_categories {
            title
            title_RO
            id
          }
          categories {
            title
            title_RO
            id
            filter
          }
          age_categories {
            title
            title_RO
          }
          featured_image {
            absolutePath
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
                src
              }
            }
          }
          muscle_group {
            absolutePath
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
                src
              }
            }
          }
          content_RO
          description_RO
          title_RO
        }
      }
    }
    allStrapiSubCategories {
      edges {
        node {
          categories {
            filter
            title
            title_RO
          }
          title
          title_RO
          strapiId
        }
      }
    }
    allStrapiFilters {
      edges {
        node {
          title
          title_RO
          id
          strapiId
        }
      }
    }
  }
`;

const Blog = ({ data }) => {
  const [title, setTitle] = useState('');
  const [, setUrl] = useState('');
  const [sharedURL, setSharedURL] = useState('');

  useEffect(() => {
    var pathArray = window.location.pathname.split('/');
    setTitle(pathArray[pathArray.length - 1]);
    setUrl(window.location.protocol + '//' + window.location.hostname);
    setSharedURL(window.location.href);
  }, [title]);

  const postData = data.allStrapiExercises.edges[0].node;
  const subCategoryData = data.allStrapiSubCategories.edges;
  const filterData = data.allStrapiFilters.edges;

  const isBrowser = typeof window !== 'undefined';

  return (
    <>
      <Layout>
        <Seo
          title={
            isBrowser && window && window.localStorage.getItem('lang') === 'ro'
              ? 'Tobsos - Exerciții'
              : 'Tobsos - Exercises'
          }
          description={
            isBrowser && window && window.localStorage.getItem('lang') === 'ro'
              ? 'Cu cât fundația fizică a jucătorilor este mai puternică, cu atât sunt mai mari șansele de a deveni un sportiv de top.'
              : 'The stronger the players physical foundation the higher the chances of becoming a top athlete.'
          }
          keywords="fitness, training, sport, young, tineret"
          url="https://tobsos.com/"
        />
        <TopBar />
        <Content
          postData={postData}
          subCategoryData={subCategoryData}
          filterData={filterData}
        />
      </Layout>
    </>
  );
};

export default Blog;
