import React, { useEffect } from 'react';
import '../../../../components/common/i18n';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import { Col, Row } from '../../../common/common.style';
import {
  Container,
  PostDetails,
  PostDetailsChild,
  PostDetailsCategories,
  PostCategory,
  PostCategoryEmpty,
  CategoriesContainer,
  FeaturedImageWrap,
  H1,
  H3,
  Image,
  ImageEffect,
  RM as ReactMarkdown,
} from './content.style';

// import ReactMarkdown from "react-markdown"

// const readingTimeProcessing = text => {
//   const wordCount = text.replace(/[^\w ]/g, "").split(/\s+/).length
//   return Math.ceil(wordCount / 170) + 1 + " min"
// }

const isBrowser = typeof window !== 'undefined';

const Content = ({ postData, subCategoryData, filterData }) => {
  useEffect(() => {
    if (
      postData.Paid === true &&
      isBrowser &&
      window &&
      !window.localStorage.getItem('codeActive')
    ) {
      window.location.href = '/exercise/';
    }
  }, []);

  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query PostImages {
      tobsosPoster: file(relativePath: { eq: "tobsosVideoPoster.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      missingImageRO: file(relativePath: { eq: "missing_Image_RO2.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      missingImageEN: file(relativePath: { eq: "missing_Image_EN.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const sortFilterData = (a, b) => {
    let retval = a.node.strapiId < b.node.strapiId ? -1 : 1;
    return retval;
  };
  filterData.sort(sortFilterData);

  const langDataPrint = (parent, typeOfDataToPrint) => {
    if (Boolean(isBrowser && window && window.localStorage.getItem('lang'))) {
      if (Boolean(parent)) {
        let lang = isBrowser && window && window.localStorage.getItem('lang');
        if (lang === 'en') {
          try {
            return parent[typeOfDataToPrint];
          } catch (error) {
            console.log(error);
          }
        } else {
          try {
            typeOfDataToPrint = typeOfDataToPrint + '_RO';
            return parent[typeOfDataToPrint];
          } catch (error) {
            console.log(error);
          }
        }
      } else {
        console.log('No Parent To Translate From!');
      }
    }
  };

  const printFilterData = (filter, index) => {
    let isFilterRelevant = false;
    postData.categories.forEach((category) => {
      if (filter.strapiId === category.filter) {
        isFilterRelevant = true;
      }
    });
    if (isFilterRelevant) {
      return (
        <PostDetailsCategories key={index}>
          <H3>
            {isBrowser && window && window.localStorage.getItem('lang') === 'en'
              ? langDataPrint(filter, 'title') !== undefined &&
                langDataPrint(filter, 'title').substring(
                  langDataPrint(filter, 'title').indexOf('by') + 3
                )
              : langDataPrint(filter, 'title') !== undefined &&
                langDataPrint(filter, 'title').substring(
                  langDataPrint(filter, 'title').indexOf('după') + 4
                )}
          </H3>
          <CategoriesContainer>
            {postData.categories.map((category, index) => (
              <>
                {category.filter === filter.strapiId && (
                  <PostCategory key={index}>
                    {langDataPrint(category, 'title')}
                  </PostCategory>
                )}
              </>
            ))}
            {postData.sub_categories.map((category, index2) => (
              <>{printSubCategory(category.id, filter.strapiId, index2)}</>
            ))}
          </CategoriesContainer>
        </PostDetailsCategories>
      );
    } else {
      return (
        <PostDetailsCategories key={index}>
          <H3>
            {isBrowser && window && window.localStorage.getItem('lang') === 'en'
              ? langDataPrint(filter, 'title') !== undefined &&
                langDataPrint(filter, 'title').substring(
                  langDataPrint(filter, 'title').indexOf('by') + 3
                )
              : langDataPrint(filter, 'title') !== undefined &&
                langDataPrint(filter, 'title').substring(
                  langDataPrint(filter, 'title').indexOf('după') + 4
                )}
          </H3>
          <CategoriesContainer>
            <PostCategoryEmpty>-</PostCategoryEmpty>
          </CategoriesContainer>
        </PostDetailsCategories>
      );
    }
  };

  const printSubCategory = (id, filterId, index) => {
    let title = '';
    subCategoryData.forEach((element) => {
      if (title !== '') return;
      if (element.node.strapiId === id) {
        let inRightCategory = false;
        element.node.categories.forEach((category) => {
          if (category.filter === filterId) {
            inRightCategory = true;
            return;
          }
        });
        if (inRightCategory) {
          title = langDataPrint(element.node, 'title');
        }
      }
    });
    if (title !== '') return <PostCategory key={index}>{title}</PostCategory>;
  };

  return (
    <Container>
      {!(
        postData.Paid === true &&
        isBrowser &&
        window &&
        !window.localStorage.getItem('codeActive')
      ) && (
        <Row>
          <Col lg={{ size: 12, offset: 0 }} md={{ size: 4 }} sm={{ size: 1 }}>
            <H1>
              {langDataPrint(postData, 'title')
                ? langDataPrint(postData, 'title')
                : isBrowser &&
                  window &&
                  window.localStorage.getItem('lang') === 'en'
                ? 'No Title'
                : 'Fara titlu'}
            </H1>

            {/* <ReadingTime>
            Reading time about {readingTimeProcessing(postData.content)}
          </ReadingTime> */}
            <FeaturedImageWrap>
              <Image
                src={
                  postData.featured_image
                    ? postData.featured_image.childImageSharp.fluid.src
                    : isBrowser &&
                      window &&
                      window.localStorage.getItem('lang') === 'en'
                    ? data.missingImageEN.childImageSharp.fluid.src
                    : data.missingImageRO.childImageSharp.fluid.src
                }
                alt={postData.title}
              />
              <ImageEffect
                src={
                  postData.featured_image
                    ? postData.featured_image.childImageSharp.fluid.src
                    : isBrowser &&
                      window &&
                      window.localStorage.getItem('lang') === 'en'
                    ? data.missingImageEN.childImageSharp.fluid.src
                    : data.missingImageRO.childImageSharp.fluid.src
                }
                alt={postData.title}
              />
            </FeaturedImageWrap>
            <PostDetails>
              <PostDetailsChild>
                <PostDetailsCategories>
                  <H3>{t('contentComponent.age')}</H3>
                  <CategoriesContainer>
                    {postData.age_categories.length > 0 ? (
                      postData.age_categories.map((category, index) => (
                        <PostCategory key={index}>
                          {langDataPrint(category, 'title')}
                        </PostCategory>
                      ))
                    ) : (
                      <PostCategory>
                        {t('articlesComponent.allAges')}
                      </PostCategory>
                    )}
                  </CategoriesContainer>
                </PostDetailsCategories>
                {filterData.map((category, i) =>
                  printFilterData(category.node, i)
                )}
              </PostDetailsChild>
              <PostDetailsChild>
                <Image
                  fillParent
                  fluid
                  src={
                    postData.muscle_group
                      ? postData.muscle_group.childImageSharp.fluid.src
                      : isBrowser &&
                        window &&
                        window.localStorage.getItem('lang') === 'en'
                      ? data.missingImageEN.childImageSharp.fluid.src
                      : data.missingImageRO.childImageSharp.fluid.src
                  }
                ></Image>
              </PostDetailsChild>
            </PostDetails>
            <ReactMarkdown
              children={langDataPrint(postData, 'content')}
              components={{
                img: ({ node, ...props }) => (
                  <>
                    {props && props.src.includes('youtu') ? (
                      <iframe
                        width="400"
                        src={
                          'https://www.youtube-nocookie.com/embed/' +
                          props.src.slice(-11) +
                          '?rel=0&amp;modestbranding=1'
                        }
                      ></iframe>
                    ) : (
                      // <iframe width="420" height="345" src={props.src}></iframe>
                      // <video
                      //   width="400"
                      //   poster={}
                      //   controls
                      //   preload="metadata"
                      // >
                      //   <source
                      //     {...props}
                      //     src={props.src + "#t=0.7"}
                      //     type="video/mp4"
                      //   />
                      //   Your browser does not support HTML video.
                      // </video>
                      <img {...props} />
                    )}
                  </>
                ),
              }}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Content;
